import * as React from "react";
import noOp from "@/helpers/noOp";

type HasSeenSpotlightProps = {
  hasSeenSpotlight?: boolean;
  setHasSeenSpotlight: (value: boolean) => void;
};

export const HasSeenSpotlightContext =
  React.createContext<HasSeenSpotlightProps>({
    hasSeenSpotlight: undefined,
    setHasSeenSpotlight: noOp,
  });
export const HasSeenSpotlightProvider = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    const [hasSeenSpotlight, setHasSeenSpotlight] = React.useState(false);
    const value = React.useMemo(
      () => ({
        hasSeenSpotlight,
        setHasSeenSpotlight,
      }),
      [hasSeenSpotlight],
    );
    return (
      <HasSeenSpotlightContext.Provider value={value}>
        {children}
      </HasSeenSpotlightContext.Provider>
    );
  },
);
