import * as React from "react";
import { spotlightContentIds, useSpotlight } from "./hooks/useSpotlight";
import { useSpotlightData } from "./hooks/useSpotlightData";
import { TrackEventOnMount, useTrackEvent } from "@/domains/App";
import { withControllerPerformanceMetrics } from "@/domains/App/components/Controller";
import { OnboardingBillPayModal as BillPayWelcomeModal } from "@/domains/Cash/features/AccountsPayable";
import { AllAnalyticsEvents } from "@/features/Analytics/sharedTypes";
import LimitDropPendingModalController from "@/features/ExternalAccountsResolutionCenter/controllers/LimitDropPendingModalController";
import RegisterPhoneNumberSpotlightController from "@/features/RegisterPhoneNumber/controllers/RegisterPhoneNumberSpotlightController";
import StaleFinancialsLimitWarningModalController from "@/features/StaleFinancialsLimitWarningModal/controllers/StaleFinancialsLimitWarningModalController";
import { useIsArfiTakeoverOpen } from "@/hooks/useIsArfiTakeoverOpen";
import { useIsProductAnnouncementRoute } from "@/hooks/useIsProductAnnouncementRoute";
import { useSandbox } from "@/modules/Sandbox/hooks/useSandbox";
import CompanyPolicyAgreementTakeoverController from "@/routes/Onboarding/OnboardingExperience/controllers/CompanyPolicyAgreementTakeoverController";

const MFASetup = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MFASetupController" */
      "@/features/2FA/components/NewMFASteps/MFASetup"
    ),
);

// A modal that determines its visibility state and any internal state
// from the URL, and discards that state on close.
// If you don't need to store state in the URL, you're probably better off
// using a regular modal with component local state.
export const Spotlight: React.FC = () => {
  const {
    openSpotlight,
    closeSpotlight,
    spotlightContentId,
    setHasSeenSpotlight,
    isViewingSpotlight,
  } = useSpotlight();
  const isProductAnnouncementRoute = useIsProductAnnouncementRoute();
  const isArfiOpen = useIsArfiTakeoverOpen();
  const track = useTrackEvent();

  const {
    loading,
    shouldSeeRegisterPhoneNumber,
    shouldSeeMFAV2,
    shouldSeeBillPayWelcomeModal,
    shouldSeeLimitDropPendingModal,
    shouldSeeStaleFinancialsLimitWarningModal,
    billForwardingEmailAddress,
    shouldSeeCompanyPolicyAgreement,
  } = useSpotlightData();

  const shouldNotShowSpotlight =
    !isProductAnnouncementRoute || isArfiOpen || loading;

  const onComplete = React.useCallback(
    (skipAnalyticEvent = false) => {
      if (!skipAnalyticEvent) {
        track(AllAnalyticsEvents.SpotlightCompleted, {
          spotlightContentId,
        });
      }
      closeSpotlight();
    },
    [closeSpotlight, spotlightContentId, track],
  );

  React.useEffect(() => {
    if (shouldNotShowSpotlight || isViewingSpotlight) {
      return;
    }

    const spotlightPermissionsMap: { [key: string]: boolean } = {
      [spotlightContentIds.registerPhoneNumber]: shouldSeeRegisterPhoneNumber,
      [spotlightContentIds.MFAV2]: shouldSeeMFAV2,
      [spotlightContentIds.companyPolicyAgreement]:
        shouldSeeCompanyPolicyAgreement,
      [spotlightContentIds.limitDropPendingModal]:
        shouldSeeLimitDropPendingModal,
      [spotlightContentIds.staleFinancialsLimitWarningModal]:
        shouldSeeStaleFinancialsLimitWarningModal,
      [spotlightContentIds.billPayWelcome]: shouldSeeBillPayWelcomeModal,
    };

    if (spotlightPermissionsMap[spotlightContentIds.registerPhoneNumber]) {
      openSpotlight(spotlightContentIds.registerPhoneNumber);
    } else if (spotlightPermissionsMap[spotlightContentIds.MFAV2]) {
      openSpotlight(spotlightContentIds.MFAV2);
    } else if (
      spotlightPermissionsMap[spotlightContentIds.companyPolicyAgreement]
    ) {
      openSpotlight(spotlightContentIds.companyPolicyAgreement);
    } else if (
      spotlightPermissionsMap[
        spotlightContentIds.staleFinancialsLimitWarningModal
      ]
    ) {
      openSpotlight(spotlightContentIds.staleFinancialsLimitWarningModal);
    } else if (
      spotlightPermissionsMap[spotlightContentIds.limitDropPendingModal]
    ) {
      openSpotlight(spotlightContentIds.limitDropPendingModal);
    } else if (spotlightPermissionsMap[spotlightContentIds.billPayWelcome]) {
      openSpotlight(spotlightContentIds.billPayWelcome);
    }

    // remove spotlight query param if user does not have access to spotlight
    // or does not exist on permissions map
    if (
      Object.entries(spotlightPermissionsMap).some(
        ([spotlightId, spotlightPermission]) =>
          spotlightContentId === spotlightId && !spotlightPermission,
      ) ||
      (spotlightContentId && !spotlightPermissionsMap[spotlightContentId])
    ) {
      closeSpotlight();
    }
  }, [
    isViewingSpotlight,
    shouldNotShowSpotlight,
    shouldSeeBillPayWelcomeModal,
    shouldSeeRegisterPhoneNumber,
    shouldSeeMFAV2,
    shouldSeeCompanyPolicyAgreement,
    spotlightContentId,
    closeSpotlight,
    openSpotlight,
    shouldSeeStaleFinancialsLimitWarningModal,
    shouldSeeLimitDropPendingModal,
  ]);

  React.useEffect(() => {
    if (spotlightContentId) {
      setHasSeenSpotlight(true);
    }
  }, [setHasSeenSpotlight, spotlightContentId]);

  if (!spotlightContentId || shouldNotShowSpotlight) {
    return null;
  }

  const SpotlightMapObject = {
    [spotlightContentIds.registerPhoneNumber]: (
      <RegisterPhoneNumberSpotlightController onComplete={onComplete} />
    ),
    [spotlightContentIds.MFAV2]: <MFASetup onComplete={onComplete} />,
    [spotlightContentIds.companyPolicyAgreement]: (
      <CompanyPolicyAgreementTakeoverController onComplete={closeSpotlight} />
    ),
    [spotlightContentIds.limitDropPendingModal]: (
      <LimitDropPendingModalController onClose={closeSpotlight} />
    ),
    [spotlightContentIds.staleFinancialsLimitWarningModal]: (
      <StaleFinancialsLimitWarningModalController
        onClose={closeSpotlight}
        onComplete={closeSpotlight}
      />
    ),
    [spotlightContentIds.billPayWelcome]: (
      <BillPayWelcomeModal
        forwardingEmailAddress={billForwardingEmailAddress}
        onClose={closeSpotlight}
      />
    ),
  };

  return (
    <TrackEventOnMount
      eventName={AllAnalyticsEvents.SpotlightStarted}
      eventProps={{
        spotlightContentId,
      }}
    >
      {SpotlightMapObject[
        spotlightContentId as keyof typeof SpotlightMapObject
      ] || null}
    </TrackEventOnMount>
  );
};

const GatedSpotlightController = () => {
  const { isInSandboxMode } = useSandbox();
  return isInSandboxMode ? null : <Spotlight />;
};

export default withControllerPerformanceMetrics(GatedSpotlightController);
