import { useLocation } from "react-router-dom";
import { ChannelStatus, SeenFlagKey } from "@/__generated__/globalTypes";
import { AccountStatus } from "@/data/sharedTypes";
import { useAccountStatus } from "@/domains/Primitives/shared/hooks/useAccountStatus";
import { useMockableShouldSeeMfa } from "@/features/2FA/hooks/useShouldSeeMfa";
import {
  useUserId,
  useUserProperties,
} from "@/features/Authentication/contexts/UserProperties";
import { useCopilot } from "@/features/Copilot/hooks/useCopilot";
import { useShouldShowLimitDropPendingModal } from "@/features/ExternalAccountsResolutionCenter/hooks/useShouldShowLimitDropPendingModal";
import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";
import { useMockableFspConfig } from "@/features/ProAccess";
import { useSeenFlag } from "@/features/SeenFlag";
import { useSpotlightQuery } from "@/features/Spotlight/data/queries";
import { useShouldShowStaleFinancialsLimitWarnings } from "@/features/StaleFinancialsLimitWarningModal/hooks/useShouldShowStaleFinancialsLimitWarnings";
import { connectionToNodes } from "@/helpers";
import { useMockableBillPayPermissions } from "@/hooks/useBillPayPermissions";
import { useBillPayUrls } from "@/hooks/useBillPayUrls";
import { usePersonalization } from "@/routes/Onboarding/OnboardingExperience/hooks/usePersonalization";

export const useSpotlightData = () => {
  const applicantCustomerUserId = useUserId()!;
  const { pathname } = useLocation();
  const { canSeeBillsPage, canSendBillPayment } =
    useMockableBillPayPermissions();
  const { billPayRootUrl } = useBillPayUrls();
  const { fspDetails } = useMockableFspConfig();
  const { accountStatus } = useAccountStatus();
  const { skipPhoneNumberRequirement } = useFeatureFlags();
  const { getIsInCopilotMode } = useCopilot();
  const isInCopilotMode = getIsInCopilotMode();
  const { userEmail } = useUserProperties();

  const isViewingBillPage = pathname === billPayRootUrl;
  const { data, loading } = useSpotlightQuery({
    applicantCustomerUserId,
    shouldFetchBillForwardingEmailAddress: canSeeBillsPage && isViewingBillPage,
  });

  const { shouldSeeStaleFinancialsLimitWarningModal } =
    useShouldShowStaleFinancialsLimitWarnings();

  const { shouldSeeLimitDropPendingModal } =
    useShouldShowLimitDropPendingModal();

  const {
    value: hasSeenBillPayWelcomeModal,
    isLoading: isLoadingHasSeenBillPayWelcomeModal,
    hasError: hasSeenBillPayWelcomeModalError,
  } = useSeenFlag({
    key: SeenFlagKey.BILLS_GLOBAL_ONBOARDING_MODAL_SEEN,
  });

  const {
    value: hasSeenCompanyPolicyAgreement,
    isLoading: isLoadingHasSeenCompanyPolicyAgreement,
    hasError: hasSeenCompanyPolicyAgreementError,
  } = useSeenFlag({
    key: SeenFlagKey.ENGAGEMENT_COMPANY_POLICY_AGREEMENT_ACCEPTED,
  });

  const {
    dbaName,
    billForwardingEmailAddress,
    id: accountId,
  } = data?.account ?? {};

  const personalization = usePersonalization(accountId, dbaName);
  const isCompanyPolicyAgreementEnabled =
    !!data?.shouldShowCompanyPolicyAgreement;
  const hasCompanyPolicyAgreement =
    !!personalization.companyPolicyAgreementFileId;

  const userIsSsoEnabled = Boolean(data?.ssoMeta?.enabled);
  const userIsMissingSmsChannel =
    connectionToNodes(data?.user?.channels).filter(
      (channel) =>
        channel?.status === ChannelStatus.READY &&
        channel?.options?.__typename === "ChannelSmsOptions",
    ).length === 0;
  const shouldSeeRegisterPhoneNumber =
    !isInCopilotMode &&
    userIsSsoEnabled &&
    userIsMissingSmsChannel &&
    accountStatus === AccountStatus.ACTIVE &&
    // Prevent support users from needing to register phone number
    !Boolean(userEmail?.match(/.*(\-pilot\-support\+admin@brex.com)/)) &&
    !skipPhoneNumberRequirement;

  const { shouldSeeMfaSpotlight: shouldSeeMFAModal, shouldSeeMfaLoading } =
    useMockableShouldSeeMfa(shouldSeeRegisterPhoneNumber);
  const realShouldSeeMfaModal =
    shouldSeeMFAModal &&
    !userIsSsoEnabled &&
    accountStatus === AccountStatus.ACTIVE;
  const shouldSeeBillPayWelcomeModal =
    canSeeBillsPage &&
    canSendBillPayment &&
    isViewingBillPage &&
    !hasSeenBillPayWelcomeModalError &&
    !hasSeenBillPayWelcomeModal;

  const shouldSeeCompanyPolicyAgreement =
    isCompanyPolicyAgreementEnabled &&
    hasCompanyPolicyAgreement &&
    !hasSeenCompanyPolicyAgreement &&
    !hasSeenCompanyPolicyAgreementError;

  return {
    loading:
      loading ||
      isLoadingHasSeenBillPayWelcomeModal ||
      isLoadingHasSeenCompanyPolicyAgreement ||
      shouldSeeMfaLoading,
    shouldSeeRegisterPhoneNumber: shouldSeeRegisterPhoneNumber && !fspDetails,
    shouldSeeMFAV2: !!realShouldSeeMfaModal && !fspDetails,
    shouldSeeBillPayWelcomeModal: !!shouldSeeBillPayWelcomeModal,
    shouldSeeStaleFinancialsLimitWarningModal,
    shouldSeeLimitDropPendingModal,
    billForwardingEmailAddress: billForwardingEmailAddress ?? "",
    shouldSeeCompanyPolicyAgreement: !!shouldSeeCompanyPolicyAgreement,
  };
};
