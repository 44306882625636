import * as React from "react";
import { HasSeenSpotlightContext } from "@/features/Spotlight/contexts/HasSeenSpotlightContext";
import useQueryParams from "@/hooks/useQueryParams";

export const spotlightQueryParam = "spotlightContentId";
export const spotlightContentIds = {
  billPayWelcome: "billPayWelcome",
  limitDropPendingModal: "limitDropPendingModal",
  staleFinancialsLimitWarningModal: "staleFinancialsLimitWarningModal",
  registerPhoneNumber: "registerPhoneNumber",
  MFAV2: "MFAV2",
  companyPolicyAgreement: "companyPolicyAgreement",
} as const;

export const useSpotlight = () => {
  const { hasSeenSpotlight, setHasSeenSpotlight } = React.useContext(
    HasSeenSpotlightContext,
  );
  const [isViewingSpotlight, setIsViewingSpotlight] = React.useState(false);
  const { queryParams, setQueryParam, removeQueryParam } = useQueryParams();
  const spotlightContentId = queryParams[spotlightQueryParam];

  React.useEffect(() => {
    if (!spotlightContentId) {
      setIsViewingSpotlight(false);
    }
  }, [spotlightContentId]);

  const closeSpotlight = React.useCallback(() => {
    removeQueryParam(spotlightQueryParam);
  }, [removeQueryParam]);
  const openSpotlight = React.useCallback(
    (key: keyof typeof spotlightContentIds) => {
      setQueryParam(spotlightQueryParam, key);
      setIsViewingSpotlight(true);
    },
    [setQueryParam],
  );

  return {
    openSpotlight,
    closeSpotlight,
    spotlightContentId,
    hasSeenSpotlight,
    setHasSeenSpotlight,
    isViewingSpotlight,
  };
};
