import gql from "graphql-tag";
import type {
  SpotlightQuery,
  SpotlightQueryVariables,
} from "./__generated__/queries.generated";
import { useQuery } from "@/features/Apollo";

export type { SpotlightQuery } from "./__generated__/queries.generated";

export const spotlightQuery = gql`
  query SpotlightQuery(
    $applicantCustomerUserId: ID!
    $shouldFetchBillForwardingEmailAddress: Boolean!
  ) {
    shouldShowCompanyPolicyAgreement
    user {
      id
      role
      isInitialApplicant
      hasOnboarded
      channels(last: 1000) {
        edges {
          node {
            id
            options {
              __typename
              ... on ChannelSmsOptions {
                __typename
                number
              }
            }
            status
          }
        }
      }
    }
    ssoMeta {
      enabled
    }
    account {
      id
      dbaName
      billForwardingEmailAddress
        @include(if: $shouldFetchBillForwardingEmailAddress)
    }
    riskEvaluationApplication: onboardingApplications(
      last: 1
      type: RISK_EVALUATION
      applicantCustomerUserId: $applicantCustomerUserId
    ) {
      edges {
        node {
          id
          status
          insertedAt
        }
      }
    }
  }
`;

export const disconnectedBankConnectionModalQuery = gql`
  query DisconnectedBankConnectionModalQuery {
    underwritingModelClassName
    account {
      id
      financialSources(first: 100) {
        edges {
          node {
            id
            fixRequired
          }
        }
      }
    }
  }
`;

export const useSpotlightQuery = ({
  applicantCustomerUserId,
  shouldFetchBillForwardingEmailAddress,
}: SpotlightQueryVariables) =>
  useQuery<SpotlightQuery, SpotlightQueryVariables>(spotlightQuery, {
    variables: {
      applicantCustomerUserId,
      shouldFetchBillForwardingEmailAddress,
    },
  });
